import React from 'react';
import Button from './common/Button';
import EnhancedVideoPlayer from './common/EnhancedVideoPlayer';
import useIntersectionObserver from '../utils/useIntersectionObserver';
import { Link } from 'react-router-dom';

import heroBackgroundWithChips from './assets/hero-background.png';
import demoVideo from './assets/demo-video.mp4';
import demoThumbnail from './assets/hero-background.png';
import Step1 from './assets/Step-1.png';
import Step2 from './assets/Step-2.png';
import Step3 from './assets/Step-3.png';
import GoogleFormsLogo from './assets/google-forms-logo.png'; 
import Chip1 from './assets/chip-1.png';
import Chip2 from './assets/chip-2.png';
import Chip3 from './assets/chip-3.png';

const AnimatedSection = ({ children, className }) => {
  const [ref, isIntersecting] = useIntersectionObserver({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <div
      ref={ref}
      className={`transition-all duration-1000 transform ${
        isIntersecting ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
      } ${className}`}
    >
      {children}
    </div>
  );
};

const LandingPage = () => {
  return (
    <div className="min-h-screen font-sans relative">
      <main className="space-y-16 sm:space-y-20 md:space-y-24">
        <AnimatedSection>
          <section className="relative bg-gray-100 lg:bg-transparent pt-16 pb-20 sm:pt-20 sm:pb-24">
            <div 
              className="hidden lg:block absolute inset-0 bg-cover bg-center bg-no-repeat"
              style={{ 
                backgroundImage: `url(${heroBackgroundWithChips})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            ></div>
            <div className="relative z-10 container mx-auto px-4">
              {/* Chip elements */}
              <div className="hidden lg:block">
                <img src={Chip1} alt="Realtime analytics" className="absolute top-0 left-0 w-56 h-auto" />
                <img src={Chip2} alt="Easy setup" className="absolute bottom-1/2 left-4 w-48 h-auto" />
                <img src={Chip3} alt="Interactive" className="absolute top-1/4 right-0 w-52 h-auto" />
              </div>
              
              <h1 className="text-4xl sm:text-5xl font-bold mb-6 font-bricolage-grotesque text-black text-center relative">
            Give your Google Forms a<br className="hidden sm:inline" />
                Typeform like makeover
                <span className="inline-flex items-center align-text-bottom ml-2">
                  <img 
                    src={GoogleFormsLogo} 
                    alt="Google Forms Logo" 
                    className="w-auto h-8 sm:h-12 object-contain transform rotate-12 animate-float"
                  />
                </span>
              </h1>

              <p className="text-base sm:text-lg text-gray-700 mb-8 font-inter text-center">
                You don't need typeform, Just a handy Google Form & Formlite. And Voila, your Google Forms<br className="hidden sm:inline" />
                gets a much needed makeover
              </p>

              <div className="flex flex-row sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mb-12">
                {/* <Button className="bg-white text-gray-800 border border-gray-300 hover:bg-gray-100 py-3 px-6 rounded-lg font-inter">
                  See how it works
                </Button> */}
               <Link to="/sign-in">
                <Button className="bg-indigo-600 text-white hover:bg-indigo-700 py-3 px-6 rounded-lg font-inter" shimmer={true}>
                  Get started - It's free!
                  <span className="ml-2">🎉</span>
                </Button>
            </Link>
              </div>

              <h2 className="text-xl sm:text-2xl font-bold mt-16 mb-12 font-bricolage-grotesque text-center">See how it works?</h2>
              <div className="text-center mb-8">
                <a 
                  href="https://www.formlite.co/public-form/1FAIpQLSdjhtI3gkWlf7sdYk2imCAQH_niCR1hVUHeoFElqVNVcjP5Lg?template=Interactive" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-indigo-600 hover:text-indigo-800 underline font-medium"
                >
                  Try our demo form
                </a>
                </div>
              <div className="bg-white bg-opacity-90 rounded-xl shadow-[0_0_0_6px_rgba(201,200,200,0.25)] max-w-3xl mx-auto overflow-hidden">
                <EnhancedVideoPlayer 
                  url={demoVideo} 
                  light={demoThumbnail}
                />
              </div>
            </div>
          </section>
        </AnimatedSection>

        <AnimatedSection>
          <section className="container mx-auto px-4">
            <h2 className="text-3xl sm:text-4xl lg:text-4xl font-bold text-center mb-4 font-bricolage-grotesque">Get started in 3 easy steps</h2>
            <p className="text-center text-gray-600 mb-12">It's as easy as it gets 👋</p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
              {[
                { title: "Paste your google form link", image: Step1 },
                { title: "Choose a template", image: Step2 },
                { title: "Share your interactive form", image: Step3 }
              ].map((step, index) => (
                <div key={index} className="flex flex-col items-center">
                  <img 
                    src={step.image} 
                    alt={`Step ${index + 1}`} 
                    className="w-42 h-42 sm:w-46 sm:h-46 object-contain mb-4"
                  />
                  <p className="text-md font-medium text-center">{`${index + 1}: ${step.title}`}</p>
                </div>
              ))}
            </div>
          </section>
        </AnimatedSection>

        <AnimatedSection>
          <section className="container mx-auto px-4">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-4 font-bricolage-grotesque">Full access. One time payment</h2>
            <p className="text-center text-gray-600 mb-8">Be an early adopter coz it has its own perks. 🦄</p>

            <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100">
              <div className="flex flex-col md:flex-row">
                <div className="p-6 md:w-1/2 md:border-r border-gray-200">
                  <h3 className="text-2xl font-bold mb-2 font-bricolage-grotesque">Life time deal</h3>
                  <p className="text-gray-600 mb-4">2/30 sold. Hurry up</p>
                  <div className="flex items-baseline mb-4">
                    <span className="text-4xl font-bold">$69</span>
                    <span className="text-xl text-gray-400 line-through ml-2">$99</span>
                  </div>
                  <p className="text-gray-600 mb-6">One time payment</p>
                  <Link to="/sign-in">
                  <Button className="w-full bg-indigo-600 text-white hover:bg-indigo-700 py-3 px-6 rounded-lg text-lg" shimmer={true}>
                    Grab the deal!
                  </Button>
                  </Link>
                </div>
                <div className="p-6 md:w-1/2 bg-gray-50">
                  <h4 className="font-semibold mb-2 font-bricolage-grotesque">What are the limits of free plan?</h4>
                  <p className="text-gray-600 mb-4">1 form with one template</p>
                  <h4 className="font-semibold mb-2 font-bricolage-grotesque">What's included in one-time payment?</h4>
                  <p className="text-gray-600 mb-4">Access to current and upcoming features.</p>
                  <h4 className="font-semibold mb-2 font-bricolage-grotesque">Does it support private forms?</h4>
                  <p className="text-gray-600">No, as of now we support public forms ideal for public facing use cases like surveys, polls, waitlists, quizzes etc.</p>
                </div>
              </div>
            </div>
          </section>
        </AnimatedSection>
      </main>

      <footer className="mt-16 sm:mt-20 py-6 sm:py-8 bg-white">
        <div className="container mx-auto px-4 text-center text-gray-600">
          <p>© 2024 Formlite · Terms & Privacy</p>
        </div>
      </footer>

      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translateY(0) rotate(12deg); }
          50% { transform: translateY(-5px) rotate(12deg); }
        }
        .animate-float {
          animation: float 3s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default LandingPage;
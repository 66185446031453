import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const EnhancedVideoPlayer = ({ url, light }) => {
  const [playing, setPlaying] = useState(false);

  const handlePlay = () => {
    setPlaying(true);
  };

  return (
    <div className="relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
      <ReactPlayer
        url={url}
        light={light}
        width="100%"
        height="100%"
        controls={true}
        playing={playing}
        onPlay={handlePlay}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              disablePictureInPicture: true,
            },
          },
        }}
        playIcon={
          <button 
            className="bg-black bg-opacity-60 rounded-full p-4 transition-transform transform hover:scale-110"
            onClick={handlePlay}
          >
            <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 5V19L19 12L8 5Z" fill="white"/>
            </svg>
          </button>
        }
      />
    </div>
  );
};

export default EnhancedVideoPlayer;
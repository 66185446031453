import React from 'react';

const Button = ({ children, className, as = 'button', shimmer = false, ...props }) => {
  const Component = as;
  return (
    <Component
      className={`relative overflow-hidden px-4 py-2 rounded-md transition duration-300 ease-in-out ${className}`}
      {...props}
    >
      {children}
      {shimmer && <div className="absolute top-0 left-0 w-full h-full shimmer-effect"></div>}
    </Component>
  );
};

export default Button;
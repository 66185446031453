import React from 'react';

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

const MultipleChoiceGridTF = ({ question, gridAnswers, handleMultipleChoiceGridSelection, preferences}) => {
  return (
    <div className="w-full">
      <div className="flex mb-4">
        <div className="w-1/3"></div>
        {question.options.map((option, index) => (
          <div key={`column-${index}`} className="flex-1 text-center font-medium" style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor}}>
            {option.value}
          </div>
        ))}
      </div>
      <div className="space-y-2">
        {question.rows.map((row) => (
          <div 
            key={row.entry} 
            className="flex items-center rounded-lg"
            style={{
              backgroundColor: `rgba(${hexToRgb(preferences?.buttonColor)}, 0.1)`,
            }}
          >
            <div className="w-1/3 py-2 px-4 font-medium" style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor}}>{row.value}</div>
            {question.options.map((option) => (
              <div key={`${row.entry}-${option.value}`} className="flex-1 flex items-center justify-center py-2 px-4">
                <label className="inline-flex items-center justify-center relative w-5 h-5">
                  <input
                    type="radio"
                    name={row.entry}
                    value={option.value}
                    checked={gridAnswers[row.entry] === option.value}
                    onChange={() => handleMultipleChoiceGridSelection(question.entry, row.entry, option.value)}
                    className="absolute opacity-0 w-0 h-0"
                  />
                  <span className="flex items-center justify-center absolute top-0 left-0 w-5 h-5 border rounded-full cursor-pointer" style={{borderColor: preferences?.buttonColor}}>
                    {gridAnswers[row.entry] === option.value && (
                      <svg className="w-5 h-5 pointer-events-none" viewBox="0 0 20 20" fill={preferences?.buttonColor}>
                        <circle cx="10" cy="10" r="10" />
                        <path d="M6 10l3 3 5-5" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}
                  </span>
                </label>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );  
};

export default MultipleChoiceGridTF;